<template>
  <div class="about page">
    <h1>Quantum Kitty</h1>
<br>
<h3>About Quantum Kitty</h3>
<div class="infoSmall">
  <p>
  Quantum Kitty is a small orginization trying to find unique ways to fund and promote positive change 
  in our world.  We are all in this together so we might as well work together.  It has been shown that 
  something a simple as a smile can have a positive effect on someone's day, just think what going out 
  of your way to spread positivity could do.  If we work together we can spread a wave of positive energy, 
  maybe even a pandemic of positivity.  It might be kinda scary for some people when everyone around them 
  is smiling at them, probably think that they are in some kind of horror movie or hidden camera show - but
  we can worry about that when we get there.  In the mean time, go out, have fun, do good things, and 
  create a better world.
  </p>
  <br>
  <br>
  <h3>Contact Us</h3>
  <p>
  If you are having issues with the website, you have suggestion, or just want to share some way you have 
  done good things or we could do good things, you can contact us at:  
  </p>
  <p>
    EMail:  Admin@QuantumKitty.org
  </p>
  <p>
    Phone:  720-588-9480
  </p>
</div>
<br>
<br>
  <div class="bot-menu">
      <div>
          <router-link class="btn" to="/">Go Home</router-link>
      </div>
      <div class="pic">
          <img width="100" class="kitty" src="@/assets/cat-sleeping.png" alt="Sleepy Kitty">
      </div>
      <div>
          <router-link class="btn" to="/UserHome">Go to Games</router-link>
      </div>  
  </div>
  <br><br>
  <!-- <img height='0' width='0' alt='' src='https://pixel.watch/k2p1' />  -->
  </div>
</template>

