import { ref } from 'vue'

// Firebase imports
import { firebaseAuth  } from '../firebase/config'
import { signInWithEmailAndPassword } from 'firebase/auth'

const error = ref(null)
const isPending = ref(false)

const login = async (email, password) => {
    error.value = null
    isPending.value = true

    try {
        const res = await signInWithEmailAndPassword(firebaseAuth, email, password)
        if (!res) {
            throw new error('Could not login...')
        }

        error.value = null
        isPending.value = false
    }
    catch(err) {
        console.log('err.message = ', err.message)
        error.value = err.message
        isPending.value = false
    }
}

const useLogin = () => {
    return { error, isPending, login}
}

export default useLogin