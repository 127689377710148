<template>
  <div class="home page">
    <div class="info">
      <h1>
        Quantum Kitty
      </h1>
      <br>
      <h2>WE ARE GOING TO WIN THE LOTTERY - REPEATEDLY!</h2>
      <h3>
        We going to win the lottery and are inviting you along for the ride. 
      </h3>
      <h3>
        Join us now and you can be part of the group that wins - repeatedly!
      </h3>
      <br>
      <h3>
        Why Quantum Kitty
      </h3>
      <p>
        Quantum Kitty gets its name as a cross between quantum mechanics’s famous Schrodinger’s cat and 
        the pot in gambling also known as the kitty.
      </p>
      <br>
      <p>
        Note: This is a scientific research site where research participants are allowed access 
        to scientific results in near real time which users may choose to use for their financial 
        benefit.  This is NOT a gambling site!  
      </p>
      <br>
      <h3>
        What we are trying to do
      </h3>
      <p>
        By obtaining input from a large enough group of people, we should be able to predict the outcome 
        of future events.  
      </p>
      <br>
      <h3>
        How it works / Background
      </h3>
      <p>
        In 1907, the statistician Francis Galton, famously observed that when 787 people at a country 
        fair were asked to guess the weight of an ox, the median of the guesses (1207 pounds) was 
        impressively close (within one percent) to the true value (1198 pounds) [Galton 1907b].  The 
        phenomena behind this observation has since come to be known as the “Wisdom of Crowds,” whereby 
        aggregations of non-expert estimates are surprisingly accurate.  This phenomenon has been studied 
        extensively in many varied contexts [Lorge et al 1958; Surowiecki 2005].  The “Wisdom of Crowds” 
        and the theory of collective intelligence dictates that aggregate predictions from a large crowd 
        can be statistically accurate, even rivaling predictions by experts.
      </p>
      <br>
      <p>
        In 2010, the Journal of Personality and Social Psychology published claims made by Professor Daryl 
        Bem, a Cornell University physicist, that he had proven humans have psychic abilities.  Professor 
        Bem set out to investigate parapsychology, or 'psi', through a series of nine experiments.  In one 
        test, students were shown a list of words to memorize.  They were later asked to recall as many as 
        they could, and finally were given a random selection of the words to type out.  They were, 
        unsurprisingly, more adept at remembering certain words over others; however, the words they 
        remembered tended to be the words they would later be asked to type.  This suggested the distinct 
        future event had affected their ability to recall specific words.
      </p>
      <br>
      <p>
        The idea of manifestation suggests that what we think affects what will become our reality.  
        This can be seen in something as simple as the placebo effect, where someone believing they 
        have been treated for some condition improves as if they had been treated.  The much more 
        complicated arena of quantum mechanics also suggests a “mind over matter” connection.  In the 
        amazing double slit experiment, it was shown that the behaviour of particles can be dramatically 
        altered by simply observing them.  This suggests that there exists the potential to change our 
        physical reality in ways that we have not yet fully grasped.  The idea that simply observing and 
        thinking about an expected result can influence the actual result is a difficult thing to study.  
        But due to the radical nature of the theory, scientists are continuing to develop ways to further 
        verify these findings.
      </p>
      <br>
      <h3>
        What this means for us
      </h3>
      <p>
        This tells us that we have influence over much more than we think we do and that we can change 
        many things in our circle of influence both internally and externally.  We also have the ability 
        to be influenced by and sense events that have not yet occurred. 
      </p>
      <br>
      <p> 
        How would you like to repeatedly win the lottery? 
      </p>
      <br>
      <p> 
        Click on 'Continue Reading' below to find out how we are going to take advantage of this.
      </p>
      <br>        
      <p class="quote">
        “All that we are is a result of what we have thought.”
      </p>
      <!-- <br> -->
      <p class="quote">
        – Buddha
      </p>
    </div>
    <!-- <br><br>  
    <div v-if="!user">
      <p class="bot-menu">
        Please signup for a FREE account to learn more about the Quantum Kitty project and how you can benefit.  Or 
        become a Quantum Kitty member and you can take full advantage of the 
        research results and make money by doing so. 
      </p>
    </div>    -->
      <br>
  </div>
  <div>
    <div v-if="user" class="bot-menu">
      <div>
        <router-link class="btn" to="/UserHome">Go to Games</router-link>
      </div>
      &nbsp; &nbsp;
      <div class="pic">
          <img width="100" class="kitty" src="@/assets/cat-sleeping.png" alt="Sleepy Kitty">
      </div>
      &nbsp; &nbsp;
      <div>
        <a href="/QKDetails" class="btn" >Continue Reading</a>
      </div>     
    </div>
    <div v-else class="bot-menu">
      <router-link class="btn" :to="{ name: 'Login' }">Log In</router-link>
      &nbsp; &nbsp;
      <div class="pic">
          <img width="100" class="kitty" src="@/assets/cat-sleeping.png" alt="Sleepy Kitty">
      </div>
      &nbsp; &nbsp;
        <a href="/QKDetails" class="btn" >Continue Reading</a>
      <!-- <router-link class="btn" :to="{ name: 'Signup' }">Sign Up</router-link> -->
    </div>
  </div>
  <br><br>
  <!--ClickMeter.com page views tracking: QK Home --> 
  <!-- <script type='text/javascript'> var ClickMeter_pixel_url = '//pixel.watch/a9k7'; </script>  -->
  <!-- <script type='text/javascript' id='cmpixelscript' src='//s3.amazonaws.com/scripts-clickmeter-com/js/pixelNew.js'></script>  -->
  <!-- <noscript>  -->
    <!-- <img height='0' width='0' alt='' src='https://pixel.watch/a9k7' />  -->
    <!-- </noscript> -->
</template>

<script>
import { useRouter } from 'vue-router'
import getUser from '../composables/getUser'

export default {
  name: 'Home',
  setup() {
    const { user } = getUser()
    const router = useRouter()

// console.log(process.env);

    return { user }
  }
}
</script>

