import { ref, toRaw, watchEffect } from 'vue'
import { qkdb } from '../firebase/config'
import { doc, onSnapshot, collection, query, where, getDoc } from 'firebase/firestore'
// import sayHi from './sayHi'

const getDocument = async (collection, id) => {
  const myResult = ref()
  const error = ref(null)
  
  // const thisVar = await sayHi('Bethel')
  // console.log('thisVar = ', thisVar)

  // register the firestore collection reference
  const docRef = doc(qkdb, collection, id)
  console.log('docRef = ', docRef)

////////////////////////////////////////////////////////////////////////////////////////

  const unsub = onSnapshot(docRef, (docSnap) => {
    if (docSnap.exists()) {
      console.log('Document data = ', docSnap.data())
      myResult.value = { ...docSnap.data(), id: docSnap.id }
      console.log('In getDocument - myResult.value = ', myResult.value)
  
      if(collection == "games") {
        console.log('In getDoument - extraNumber = ', myResult.value.extraNumbers)
      }      
      if(collection == "results") {
        console.log('In getDoument - mainNum = ', myResult.value.mainNum)
      }
    }
  })

//////   Both ways work now  /////////////////////////////////////////////////////////////

  // const docSnap = await getDoc(docRef)
  // if (docSnap.exists()) {
  //   console.log('Document data = ', docSnap.data())
  //   myResult.value = { ...docSnap.data(), id: docSnap.id }
  //   console.log('In getDocument - myResult.value = ', myResult.value)

  //   if(collection == "games") {
  //     console.log('In getDoument - extraNumber = ', myResult.value.extraNumbers)
  //   }      
  //   if(collection == "results") {
  //     console.log('In getDoument - mainNum = ', myResult.value.mainNum)
  //   }
  // }

  watchEffect((onInvalidate) => { 
      onInvalidate(() => unsub())
  })
console.log('Returning myResult as: ', myResult)
return await { myResult }
// return { myResult }
}


export default getDocument