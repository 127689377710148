<template>
  <div v-if="showModal">
      <Modal  @close="completeProcess" :headerText="headerText" :messageText="messageText"></Modal> 
  </div>

  <div class="home page">
    <div class="info">
      <h1>
        Training
      </h1>
        <br>
        <p v-show="disable">You must be a member to make full use of the training</p>
        <!-- <p>isMember = {{ isMember }}  disabled = {{ disable }}</p> -->
        Select Range: 
        <select v-model.number="range" name="range" id="RangeId" :disabled="disable" >
          <option value=5>1 - 5</option>
          <option value=10>1 - 10</option>
          <option value=25>1 - 25</option>
          <option value=50>1 - 50</option>
          <option value=100>1 - 100</option>
        </select>
        <br>
        Select Delay: 
        <select v-model.number="delay" name="delay" id="DelayId" :disabled="disable">
          <option value=0>0 Seconds</option>
          <option value=2>2 Seconds</option>
          <option value=5>5 Seconds</option>
          <option value=10>10 Seconds</option>
          <option value=15>15 Seconds</option>
          <option value=20>20 Seconds</option>
        </select>
        <br>
        Select Attempts: 
        <select v-model.number="attempts" name="attempts" id="AttemptsId" :disabled="disable">
          <option value=1>1</option>
          <option value=10>10</option>
          <option value=25>25</option>
          <option value=50>50</option>
          <option value=100>100</option>
        </select>
        <br><br>


        <form action="" @submit.prevent="handleSubmit">
            <h3>Train your number sensing abilities</h3>
            <p>Please select a number</p>

            <br><br>
            <div class="pickEm" >
                <div v-for="i in range" class="pickbox" :key="i"> 
                    <div class="mainPicks">
                        <input type="checkbox" :id="i" :value="i"  v-model.number="selected" @mouseup="savePicks(i)" :disabled="done">
                        <label>{{ i }}</label>
                    </div>
                </div>
            </div>
            <br>
            <br><br>

            <button :disabled="!done || isDelayed" >Show Results</button>            
        </form>
      <br>
      <div>
        <p>Remaining tries = {{ attempts - pickCount }}</p>
        <p>&nbsp Your Picks = &nbsp &nbsp  {{ picks }}</p>
        <p>Random Picks = {{ randNums }}</p>
      </div>
        <!--ClickMeter.com page views tracking: Training -->
        <!-- <script type='text/javascript'> -->
        <!-- var ClickMeter_pixel_url = '//pixel.watch/f1no'; -->
        <!-- </script> -->
        <!-- <script type='text/javascript' id='cmpixelscript' src='//s3.amazonaws.com/scripts-clickmeter-com/js/pixelNew.js'></script> -->
        <!-- <noscript> -->
        <!-- <img height='0' width='0' alt='' src='//pixel.watch/f1no' /> -->
        <!-- </noscript> -->
      </div>               
  </div>        
</template>

<script>
import { ref } from '@vue/reactivity'
import Modal from '../components/Modal.vue'
import { onMounted } from '@vue/runtime-core'
import { firebaseAuth } from '@/firebase/config'

export default {
  components: { Modal },
  name: 'Training',
  setup() {
    const range=ref(10)
    const delay=ref(0)
    const attempts=ref(1)
    // const isMember = false
    const disable=ref(false)
    const done=ref(false)
    const pick=ref('')
    const picks=ref([])
    const randNums=ref([])
    const pickCount = ref(0)
    const selected=ref([])
    const headerText = ref('')
    const messageText = ref('')
    const showModal = ref(false)
    const isDelayed = ref(0)
    // set disable when user not signed in

    onMounted(() => {
      checkSub()
    })

    const checkSub = (() => {
      let user = firebaseAuth.currentUser
      if(!user) {
        // limit them
        // disable.value = false // for testing purposes only!!
        disable.value = true
      } 
      // else {    
      //   let subsType = 'Active'
      //   let subscription =  Promise.resolve(fetchSubscription(subsType))
      //   .then((subscription) => {          
      //     if(!subscription.subscription.value) {
      //       // limit them
      //       disable.value = true
      //     } else {
      //       // give them full access
      //       disable.value = false
      //     }
      //   })
      // }
    })
 
    const savePicks = (pick) => {
      console.log('pick = ', pick)
      console.log('pick.value = ', pick.value)
      console.log('picks = ', picks)
      console.log('picks.value = ', picks.value)
      console.log('pickCount.value = ', pickCount.value)
      console.log('pickCount = ', pickCount)
      console.log('attempts = ', { attempts })
      console.log('attempts.value = ', attempts.value)
      console.log('delay.value = ', delay.value)

      let clearIt = setTimeout(() => {
        selected.value = []
      }, 1500)
      picks.value[pickCount.value] = pick

      let myPickCount = pickCount.value
      isDelayed.value++
      let randNum = setTimeout(() => {
        let myRand = Math.floor(Math.random()* range.value) + 1
        randNums.value[myPickCount] = myRand
        isDelayed.value--
        console.log('isDelayed.Value = ', isDelayed.value)
        console.log('pickCount.Value = ', pickCount.value)
        console.log('myPickCount = ', myPickCount)
        console.log('your random number between 1 & ', range.value, ' is:', myRand)   
        console.log('your stored random number is:', randNums.value[myPickCount])   
        console.log('all random numbers are:', randNums.value)   
        console.log('selected =', selected)
        console.log('selected.value =', selected.value)
        return myRand    
      }, delay.value*1000)   
        console.log('your random number between 1 & ', range.value, ' is:', randNums[pickCount.value])   

    
        pickCount.value +=1
        if (pickCount.value == attempts.value){
          done.value = true
        }
    }

    const handleSubmit = async () => {
        console.log('Need to show their picks and randNum in window')
        console.log('picks.value = ', picks.value)
        console.log('randNums.value = ', randNums.value)
        const matches = ref(0)

        for (let i = 0; i < attempts.value; i++) {
          // Look for matches
          if (picks.value[i] == randNums.value[i]){
            matches.value++
            console.log('Found a match at ', i)
            console.log('There are now ', matches.value, ' matches')
          }
        }

        // Set up results message
        let myPercent = matches.value/attempts.value
        if (attempts.value == 1){
          if (matches.value == 0){
            headerText.value = "Were you even trying?"
          } else {
            headerText.value = "Good job!"
          }
        } else {   
          if(myPercent == 0){
            headerText.value = "Were you even trying?"
          } else if(myPercent <= .1) {
            headerText.value = "Well at least you got some right..."
          } else if(myPercent <= .2) {
            headerText.value = "You might have some hope..."
          } else if(myPercent <= .3) {
            headerText.value = "Hmmm... Keep trying..."
          } else if(myPercent <= .4) {
            headerText.value = "Not too bad..."
          } else if(myPercent <= .5) {
            headerText.value = "Almost half right."
          } else if(myPercent <= .6) {
            headerText.value = "Thats good - more than half correct."
          } else if(myPercent <= .7) {
            headerText.value = "Showing promise..."
          } else if(myPercent <= .8) {
            headerText.value = "Very good!"
          } else if(myPercent <= .9){
            headerText.value = "You are amazing.."
          } else if(myPercent < 1) {
            headerText.value = "So close!"
          } else  {
            headerText.value = "You got them all right!   Call me!"
          }
        }

        messageText.value = "You got " + matches.value + " correct out of " + attempts.value + " tries giving you a" +
                            " " + myPercent*100 + "% "
        showModalMessage()
        // pop up window with results
    }

    const showModalMessage = () => {
      showModal.value = true
    }
    
    const completeProcess = () => {
      showModal.value = false
      // Clear variables for next run
      pickCount.value = 0
      picks.value = []
      randNums.value = []
      done.value = false
    }

    return {range, delay, attempts, disable, done, picks, pick, randNums, selected, // isMember,
            showModal, headerText, messageText, pickCount, isDelayed,
            savePicks, handleSubmit, showModalMessage, completeProcess}
  }
}
</script>

<style>

</style>