import { ref } from 'vue'

// Firebase imports
import { firebaseAuth  } from '../firebase/config'
// import { auth  } from '../firebase/config'
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth'

const error = ref('')
const isPending = ref(false)
const user = ref(null)


// const createAccount = async (email, password, displayName) => {
const signup = async (email, password, displayName) => {
    error.value = null
    isPending.value = true

    try {
        const res = await createUserWithEmailAndPassword(firebaseAuth, email, password)
        // const res = await createUserWithEmailAndPassword(auth, email, password)
              
        if (!res) {
            throw new error('Could not complete signup...')
        }
        await updateProfile(firebaseAuth.currentUser, { displayName: displayName})
        // await updateProfile(auth.currentUser, { displayName: displayName})
    
        error.value = null
        isPending.value = false
        return res
    }

    catch(err) {
        console.log('In catch block')
        console.log('err.message = ', err.message)
        error.value = err.message
        isPending.value = false
    }
    finally {
        console.log('In finally - firebaseAuth.currentUser = ', firebaseAuth.currentUser)
        // console.log('In finally - auth.currentUser = ', auth.currentUser)
    }

}

// const signup = async (email, password, displayName) => { 
//     createAccount(email, password, displayName) 
//     updateProfile(auth.currentUser, { displayName: displayName})            
//     // console.log('res - ', res)
//     console.log('display name updated to - ', displayName)
//     console.log('display name updated for user  - ', auth.currentUser)
//     error.value = null
// }

const useSignup = () => {
    return { error, isPending, signup }
}

export default useSignup