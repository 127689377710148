<template>
  <div class="hints">
      <div class="info">
        <h2>Hints</h2>
        <h3>Thoughts, advice, and other helpful things</h3>
        <br>
        <p>
            When buying tickets, if you are buying multiple sets of numbers based on our sugestions, buy 
            them on multiple tickets so when you win the any amounts that don't exceed the max in store 
            payout can be paid out annonymously and draw less attention. 
        </p>
        <br>
        <p>
            Some states will refuse to pay out winnings to people that they deam have won too much. As 
            unfair as that sounds the lottery commision has the final say.
        </p>
        <br>
        <p>
            Purchase tickets from multiple stores so you don't draw undue attention. 
        </p>
        <br>
        <p>
            Don't get greedy!  We don't want to milk the cash cow (the lottery system) to death! 
        </p>
        <br>
        <p>
            In the event of very large wins, it might be a good idea to seek the advice of a 
            proffesional financial adviser or attorney to try to hide your identity to try to 
            protect yourself from people who want to seperate you from your money as quickly 
            as possible.  Note: Some states do not allow you to remain annonymous when claiming 
            your prize.  
        </p>
        <br>
        <p>
            Please don't share the potential winning numbers with friends.  We have nothing against 
            sharing, share the money you earn with them or share the website with them so they can 
            help us select the winning numbers.  But if you are only sharing the winning numbers with them 
            you are hurting everyone else using the website and are risking milking the cash cow 
            (the lottery system) to death.   
        </p>
        <br>
        <p>
            Go out, have fun, do good things, and create a better world.
        </p>
        <br>
        <p>

        </p>
      </div>
    <div class="bot-menu">
        <div>
            <router-link class="btn" to="/UserHome">Go to Games</router-link>
        </div>
        <!-- <br> -->
        <div class="pic">
            <img width="100" class="kitty" src="@/assets/cat-sleeping.png" alt="Sleepy Kitty">
        </div>
        <!-- <br> -->
        <div>
            <!-- <a href="/" class="btn" >Go Home</a> -->
            <router-link class="btn" to="/">Go Home</router-link>
        </div>  
    </div>
        <br><br>  
  </div>
</template>

<script>
export default {

}
</script>

