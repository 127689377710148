<template>
  <div class="home page">
    <h3>Games</h3>    
      <div v-if="showModal">
          <Modal  @close="completeProcess" :headerText="headerText" :messageText="messageText"></Modal> 
      </div>
      <div v-for="game in games" :key="game.id">
        <div @click="handleSelectNumbers((myPicks.get(game.id)[0][0] == 'x'), game.id, game.nextDrawDate, game.closed)">

          <div class="single" id="{{key}}">
            <div class="thumbnail">
              <img :src=" game.picture "  alt="Failed to get game.picture"> 
            </div>
            <div class="info">
              <h3>{{ game.name }}</h3>
              <p>Next draw: {{ game.nextDrawDate }}</p>
              <p v-if="myPicks.get(game.id)[0][0] == 'x' && { showPicks: showPicks } " >
                Your picks: Not yet picked</p>
              <p v-else-if="{ showPicks: showPicks }" >
                Current picks: {{ myPicks.get(game.id)[0] }},  {{ myPicks.get(game.id)[1] }}</p>

              <p v-if="myPicks.get(game.id)[0][0] == 'x' && { showFavs: showFavs } " >
                Potential numbers: Must pick numbers first</p>
              <p v-else-if="myFavs.get(game.id)[0][0] == 'x' && myFavs.get(game.id)[1] == 'x' && { showFavs: showFavs } " >
                Potential numbers: None determined </p>
              <p v-else-if="myFavs.get(game.id)[0][0] == 'x' && { showFavs }" >
                Potential numbers: [],  {{ myFavs.get(game.id)[1] }}</p>
              <p v-else-if="myFavs.get(game.id)[1] == 'x'">
                Potential numbers: {{ myFavs.get(game.id)[0] }} </p>
              <p v-else>Potential numbers: {{ myFavs.get(game.id)[0] }}, {{ myFavs.get(game.id)[1] }} </p>
            </div>
            <div :class="{ icon: true }">
                <div :class="{ icon: true }" >
                  <span class="material-icons" :class="{pick: myPicks.get(game.id)[1] != 'x'}" >done</span>
                </div>
            </div>
          </div>
        </div> 
      </div>
      <br>
    <div class="bot-menu">
        <div>
            <router-link class="btn" to="/Hints">Go to Hints</router-link>
        </div>
        <div class="pic">
            <img width="100" class="kitty" src="@/assets/cat-sleeping.png" alt="Sleepy Kitty">
        </div>
        <div>
            <router-link class="btn" to="/">Go Home</router-link>
        </div>  
    </div>
    <br><br>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import { ref } from '@vue/reactivity'
import { Number } from 'core-js'
import getUser from '../composables/getUser'
import selectNumbers from './SelectNumbers'
import Modal from '../components/Modal.vue'
import sayHi from '../composables/sayHi'

// Firebase imports
import { qkdb } from '../firebase/config'
import { query, collection, where, getDocs, getDoc, doc, Timestamp } from 'firebase/firestore'

export default {
  name: 'UserHome',
  components: { Modal },
  setup() {
    const { user } = getUser()
    const router = useRouter()
    const gameDetails = ref()
    const games = ref(null)
    const game = ref()
    const showPicker = ref(false)
    const showPicks = ref(false)
    const showFavs = ref(false)
    const myPicks = new Map()
    const myFavs = new Map()
    showPicks.value = false
    const headerText = ref('')
    const messageText = ref('')
    const showModal = ref(false)

    const togglePicks = () => {
      showPicks.value = !showPicks.value
    }

    const useTools = (gameId) => {
      router.push({ name: 'GameTools', params: gameId})
    }

    const getMyData = async () => {
      let results = []
      const  gamesColl = await getDocs(collection(qkdb, 'games'))
        gamesColl.forEach(gdoc => {
          results.push({ ...gdoc.data(), id: gdoc.id})
          let thisGameID = gdoc.id
          let thisDrawDate = gdoc.data().nextDrawDate
          
          //////////////// getting current picks /////////////////////////
          myPicks.set(thisGameID, [['x','x','x','x','x'], 'x'])

          const colRef = collection(qkdb, 'picks')
          const pq = query(colRef, where('gameId', '==', thisGameID), 
              where('drawDate', '==', thisDrawDate), where('userUid', '==', user.value.uid))
        
          const querySnapshot = getDocs(pq)
            .then((querySnapshot) => {   
              querySnapshot.forEach((pdoc) => {
                if (pdoc.data().mainPicks) {
                  myPicks.set(thisGameID, [pdoc.data().mainPicks,pdoc.data().extraPicks])
                } 
              })  // end of for - picks
            }
          ) // end of then - picks
          .then(() => {
            // showPicks.value = true 
            showPicks.value = sayHi('Snail', 505) // make sure data is loaded before showing it
          })

          //////////////// getting suggested picks /////////////////////////
          myFavs.set(thisGameID, [['x','x','x','x','x'], 'x'])

          const favDocRef = doc(qkdb, 'results', thisGameID)
          const favDoc = getDoc(favDocRef)
            .then((favDoc) => {   
              if (favDoc.data().mainPicks) {                
                let myMainPicks = []
                const theseMainPicks = favDoc.data().mainPicks

                if (favDoc.data().mainPicks[0] != "") {
                  for (const value of Object.entries(theseMainPicks)) {
                    if (value[1] != "") {
                      myMainPicks.push(Number(value[1]))
                    }
                  }
                }  
                if (myMainPicks.length != 0 && favDoc.data().extraPicks != "") {  // both have values
                  myFavs.set(thisGameID, [myMainPicks,favDoc.data().extraPicks])
                } else if (myMainPicks.length != 0) { // main has value, extra empty
                  myFavs.set(thisGameID, [myMainPicks,'x'])
                } else if (favDoc.data().extraPicks != "") {  // main empty, extra has value
                  myFavs.set(thisGameID, [['x','x','x','x','x'],favDoc.data().extraPicks])
                } else {  // both empty
                  myFavs.set(thisGameID, [['x','x','x','x','x'],'x'])
                }
              } 
            })  // end of then - favs
            .then(() => {
              // showFavs.value = true
              showFavs.value = sayHi('Sloth', 501) // make sure data is loaded before showing it
            })
        
        // update values
        games.value = results
      })
      return gamesColl
    }
    getMyData()

    const handleSelectNumbers = (notYetPicked, selGameID, gameDate, closed) => {

      // if (true) {
      if (notYetPicked) {
        if  ( !closed ) {
          router.push({ name: 'SelectNumbers', params: { id: selGameID }})
        } else {
          // message about chrunching numbers 
          showModalMessageTooLate()
        }
      } else {
        // message about already selected numbers 
        showModalMessage()
      }
    }

    const showModalMessage = () => {
      headerText.value = "Already Selected"
      messageText.value = 'You have already selected the numbers for the current play period of this game '
      showModal.value = true
    }
    const showModalMessageTooLate = () => {
      headerText.value = "Picking Closed"
      messageText.value = 'Picking for this draw is currently shut down to allow us time to process the numbers ' + 
          'and select potential winners. Number selection for the next draw date will open soon.'
      showModal.value = true
    }
        
        const completeProcess = () => {
            showModal.value = false
        }

    return { games, user, selectNumbers, gameDetails, showPicks, showFavs, togglePicks,
          showPicker, game, useTools, showPicks, myPicks, myFavs, showModal, 
          handleSelectNumbers, showModalMessage, showModalMessageTooLate, completeProcess, headerText, messageText }
  }
}
</script>

