import { qkdb } from '../firebase/config'
import { doc, updateDoc, collection, where, query, getDocs, addDoc } from 'firebase/firestore'
// import sayHi from './sayHi'
import { ref, toRaw } from 'vue'
import getUser from '../composables/getUser'

const updatePicks = async (myColl, gameId, drawDate, mPicks, ePicks) => {
    const { user } = getUser()
    const error = ref(null)
    error.value = null
    console.log('myColl = ', myColl)
    const colRef = collection(qkdb, myColl)  

    const docData = {
        gameId: gameId,
        drawDate: drawDate,
        userUid: user.value.uid,
        mainPicks: toRaw(mPicks.value),
        extraPicks: ePicks.value[0]
    }
     
    const q = query(colRef, where('gameId', '==', gameId), 
        where('drawDate', '==', drawDate), where('userUid', '==', user.value.uid))
    console.log('q = ', q)

    const querySnapshot = await getDocs(q)
    // console.log('querySnapshot.empty = ', querySnapshot.empty)
    // console.log('querySnapshot.size = ', querySnapshot.size)
    
    if (querySnapshot.empty) {  // Add new picks
        await addDoc(colRef, docData)
    } else {                    // Update picks
        querySnapshot.forEach((pick) => {
            console.log('pick.id = ', pick.id)
            const myRes = updateDoc(doc(qkdb, 'picks', pick.id), docData)
        })
    }
    // const done = await sayHi('Slut')
    const myReturnValue = true
    return { myReturnValue, error }
}
export default updatePicks