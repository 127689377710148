<template>
  <NavBar />  
  <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About - from App.vue</router-link>
  </div> -->
  <router-view/>
</template>

<script>
import NavBar from './components/NavBar.vue'


export default {
  components: { NavBar },
    setup() {
                    
    }

}
</script>


