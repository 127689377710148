import { ref } from 'vue'

// Firebase imports
import { firebaseAuth  } from '../firebase/config'
// import { auth  } from '../firebase/config'
import { onAuthStateChanged } from 'firebase/auth'

// refs 
const user = ref(firebaseAuth.currentUser)
// const user = ref(auth.currentUser)

// auth changes
onAuthStateChanged(firebaseAuth, (_user) => {
// onAuthStateChanged(auth, (_user) => {
    // console.log('User state changed, current user is: ', _user)
    user.value = _user
} )

const getUser = () => {
    return { user }
}

export default getUser