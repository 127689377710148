import { qkdb } from '../firebase/config'
import { doc, updateDoc, setDoc, increment, FieldValue } from 'firebase/firestore'
// import sayHi from './sayHi'
import { ref, toRaw } from 'vue'

const updateCounts = async (myColl, gameId, drawDate, mPicks, ePicks) => {
    const buildCounts = {}
    const updateMainCounts = []
    const updateExtraCount = []
    const updateTotalCount = []

    const updateValue = increment(1)

    for (let [key, value] of Object.entries(mPicks.value)) {
        const updateKey = "".concat("m", value)        
        updateMainCounts[updateKey] = updateValue        
        // console.log('updateKey = ', updateKey, "  updateValue = ", updateValue)
    }
    
    const updateKey = "".concat("e", toRaw(ePicks).value[0])
    updateExtraCount[updateKey] = updateValue           
    // console.log('updateKey = ', updateKey, ' updateValue = ', updateValue)
    
    const updateTotalKey = "".concat("total")
    updateTotalCount[updateTotalKey] = updateValue           
    
    buildCounts.value = { ...updateMainCounts, ...updateExtraCount, ...updateTotalCount }
    // console.log('buildCounts = ', buildCounts.value)

    const error = ref(null)
    const isPending = ref(false)
    error.value = null
    isPending.value = true

    const myRes = ref()
    const countRef = doc(qkdb, myColl, gameId, 'drawDates', drawDate)
    // console.log('countRef = ', countRef)
    try {
        const myRes = await updateDoc(countRef, buildCounts.value)
        error.value = null
        isPending.value = false
    } 
    catch(err) {
        console.log('In catch(err) - about ready to setDoc for new doc')
        console.log('err.message = ', err.message)
        console.log('No document to update:', err.message.includes('No document to update:'))
        if (err.message.includes('No document to update:')) {
            setDoc(countRef, buildCounts.value)
        }
        error.value = err.message
        isPending.value = false
    }

    // const done = await sayHi('Izzy')
    const myReturnValue = true
    return { myReturnValue, error }
}
export default updateCounts