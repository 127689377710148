<template>
  <div class="navbar">
      <nav>
          <img src="@/assets/Quantum-Kitty-With-Title-Clear.png" alt="QK pic">
          <!-- <img src="@/assets/Quantum-Kitty-With-Title-Lav.jpg" alt="QK pic"> -->
          <h1><router-link :to="{ name: 'Home' }">Quantum Kitty</router-link></h1>
          <div class="links">
              <div v-if="user">
                  <span>Hi {{ user.displayName }}</span>
                  <router-link to="/">Home</router-link> |
                  <router-link to="/QKDetails">Details</router-link>
                  <router-link to="/hints">Hints</router-link>
                  <router-link to="/UserHome">Games</router-link>
                  <router-link to="/Training">Training</router-link>
                  <!-- <router-link to="/Account">Account</router-link> -->
                  <router-link to="/About">About</router-link>
                  <button class="btn" @click="handleClick" >Logout</button>
              </div>
              <div v-else>
                <router-link to="/">Home</router-link> |
                <router-link to="/QKDetails">Details</router-link>
                <router-link class="btn" :to="{ name: 'Signup' }">Sign Up</router-link>
                <router-link class="btn" :to="{ name: 'Login' }">Log In</router-link>
              </div>
          </div>
      </nav>
  </div>
</template>

<script>
import useLogout from '../composables/useLogout' 
import getUser from '../composables/getUser'
import { useRouter } from 'vue-router'
import { watch } from '@vue/runtime-core'

export default {
    setup() {
        const { logout, error } = useLogout()
        const { user } = getUser() 
        const router = useRouter()

        const handleClick = async () => {
            await logout()
            if (!error.value) {
                console.log('User logged out... Going home.')
                router.push({ name: 'Home' })
            }
            watch(user, () => {
            if(user.value) {
                console.log('About to push user home!')
                router.push({ name: 'Home' })
            }
        })
        }

        return { handleClick, user }
    }
}
</script>

