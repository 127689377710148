import { createRouter, createWebHistory } from 'vue-router'

import Home from '../views/Home.vue'
import Home2 from '../views/Home2.vue'
import QKDetails from '../views/QKDetails.vue'
import UserHome from '../views/UserHome.vue'
import SelectNumbers from '../views/SelectNumbers.vue'
import Hints from '../views/Hints.vue'
import Training from '../views/Training.vue'
import Signup from '../views/Signup.vue'
import Login from '../views/Login.vue'
// import Account from '../views/Account.vue'
import About from '../views/About.vue'
import NotFound from '../views/NotFound.vue'
// import { fetchSubscription } from '../composables/fetchSubscription'

// firebase imports
import { firebaseAuth, getCurrentUser, qkdb } from '../firebase/config'
import { collection, getDocs, query, where } from '@firebase/firestore'
// import { async } from '@firebase/util'
import { ref } from 'vue'

const requireAuth = (to, from, next) => {
  let user = firebaseAuth.currentUser
  if(!user) {
    // redirect them
    next({ name: 'Login'})
  } else {
    next()
  }
}

const requireNoUser = (to, from, next) => {
  let user = firebaseAuth.currentUser
  if(user) {
    // redirect them
    next({ name: 'Home'})
  } else {
    next()
  }

}

// const requireSub = (to, from, next) => {
//   let user = firebaseAuth.currentUser
//   if(!user) {
//     // redirect them
//     next({ name: 'Login'})
//   } else {    
//     let subsType = 'Active'
//     let subscription =  Promise.resolve(fetchSubscription(subsType))
//     // let sub =  await (fetchSubscription(subsType))
//     .then((subscription) => {
      
//       if(!subscription.subscription.value) {
//         // redirect them
//         let isSubed = false
//         next({ name: 'Account', params: { isSub: isSubed }})
//       } else {
//         next()
//       }
//     })
//   }
// }

// const requireKnownSubLevel = (to, from, next) => {
//   let user = firebaseAuth.currentUser
//   if(!user) {
//     // restrict them
//     let isSubed = false
//     next({ name: 'Training', params: { isSub: isSubed }})
//   } else {    
//     let subsType = 'Active'
//     let subscription =  Promise.resolve(fetchSubscription(subsType))
//     // let sub =  await (fetchSubscription(subsType))
//     .then((subscription) => {
      
//       if(!subscription.subscription.value) {
//         // restrict them
//         let isSubed = false
//         next({ name: 'Training', params: { isSub: isSubed }})
//       } else {
//         // give them full access
//         let isSubed = true
//         next({ name: 'Training', params: { isSub: isSubed }})
//       }
//     })
//   }
// }

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  }, 
  {
    path: '/Home2',
    name: 'Home2',
    component: Home2
  }, 
  {
    path: '/QKDetails',
    name: 'QKDetails',
    component: QKDetails,
    // beforeEnter: requireAuth
  }, 
  {
    path: '/Training',
    name: 'Training',
    component: Training,
    // props: true,
    beforeEnter: requireAuth
  }, 
  {
    path: '/signup',
    name: 'Signup',
    component: Signup,
    beforeEnter: requireNoUser
  },
  { 
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: requireNoUser
  },
  {
    path: '/UserHome',
    name: 'UserHome',
    component: UserHome,
    beforeEnter: requireAuth
  },
  {
    path: '/selectNumbers/:id',
    name: 'SelectNumbers',
    component: SelectNumbers,
    props: true,
    beforeEnter: requireAuth
  },
  {
    path: '/hints',
    name: 'Hints',
    component: Hints,
    beforeEnter: requireAuth
  },
  // {
  //   path: '/account',
  //   name: 'Account',
  //   component: Account,
  //   props: true,
  //   beforeEnter: requireAuth
  // },
  {
    path: '/about',
    name: 'About',
    component: About,
    props: true,
    // beforeEnter: requireAuth
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound
  }
  // {
  //   path: '/subscribedAccount',
  //   name: 'SubscribedAccount',
  //   component: SubscribedAccount,
  //   props: true,
  //   meta: {
  //     requiresAuth: true,
  //   }
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// const fetchSubscription = async (subsType) => {
//   const subscription = ref(null)
//   const subsQuery = ref()
//   const subsRef = collection(qkdb, 'customers', firebaseAuth.currentUser.uid, 'subscriptions')

//   if (subsType == 'All') {
//       subsQuery.value = query( subsRef, where('status', 'in', ['trialing', 'active', 'past_due', 'unpaid']) )
//       // const subsQuery.value = query( subsRef, where('status', 'in', ['trialing', 'active', 'past_due', 'unpaid']) )
//   } else if (subsType == 'Active') {
//       subsQuery.value = query( subsRef, where('status', 'in', ['active']) )
//       // const subsQuery = query( subsRef, where('status', 'in', ['active']) )
//   }

//   subscription.value = await getDocs(subsQuery.value)
//       .then((sub) => 
//           sub.docs.length > 0 ? sub.docs[0].data() : null)

//   return { subscription }
// }

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  // const isGuestOnly = to.matched.some(record => record.meta.guestOnly)

  const currentUser = await getCurrentUser()
  // console.log('currentUser = ', currentUser);


  if (requiresAuth && !currentUser) {
    next('/signup')
  // } else if (isGuestOnly && currentUser) {
  //   next('/account')
  } else {
    next()
  }
})

export default router
