<template>
  <div v-if="showModal">
      <Modal  @close="completeProcess" :headerText="headerText" :messageText="messageText"></Modal> 
  </div>
  <form @submit.prevent="handleSubmit">
    <h2>Login</h2>

    <label for="email">Email:</label>
    <input type="email" name="email" v-model="email" required>

    <label for="password">Password:</label>
    <input type="password" name="password" v-model="password" required>

    <button>Login</button>
    <div class="error" v-if="error">{{ error }}</div>
    

  </form>
  <br>
  <div>
    <button class="btn" @click="handleResetPassword">
        Reset Password
    </button>

    <button>
      <router-link :to="{ name: 'Signup' }">
        <p class="btn">Not a member yet? &nbsp; Sign up here...</p>
      </router-link>  
    </button>
  </div>
  <div class="error" v-if="errorMsg">{{ errorMsg }}</div>
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import useLogin from '../composables/useLogin'
import firebaseAuth from '../firebase/config'
import { sendPasswordResetEmail } from 'firebase/auth'
import Modal from '../components/Modal.vue'

export default {
  name: 'Login',
  components: { Modal },

  setup() {
    const email = ref('')
    const password = ref('')
    const errorMsg = ref('')
    const headerText = ref('')
    const messageText = ref('')
    const showModal = ref(false)

    const router = useRouter()
    const { login, error } = useLogin()

    const handleSubmit = async () => {
      console.log(email.value, password.value)
      await login(email.value, password.value)

      if(!error.value) {
        router.push('/')
      } else {
        if (error.value == 'Firebase: Error (auth/user-not-found).') {
          console.log('user-not-found = ', error.value)
          error.value = 'Sorry, no user found with that email & password'
        } else if (error.value == 'Firebase: Error (auth/wrong-password).') {
          console.log('user-not-found = ', error.value)
          error.value = 'Sorry, no user found with that email & password'
        } 
      }

    }

    const handleResetPassword = () => {
      console.log('Reset the password now')
      if (true) {
        showModalMessage()
      } else {        
        if (email.value) {  
          console.log('email.value =', email.value)
          // console.log('tenantID = ', firebaseAuth.tenantId);
          // firebaseAuth.tenantID = 'tempTenantID'
          // console.log('tenantID = ', firebaseAuth.tenantID);
          console.log('email =', email)
          const res = sendPasswordResetEmail(firebaseAuth, email.value)
          .then((email) => {
            console.log('In then clause with email =', email);
            // Password reset email sent!
            // ..
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log('error.code = ', error.code)
            console.log('error.message = ', error.message)
            // ..
          })
        } else {
          errorMsg.value = 'Please enter your email address...'
          console.log('errorMsg = ', errorMsg)
        }
      }
    }

    const showModalMessage = () => {
        headerText.value = "Reset Password Improvements"
        messageText.value = 'The automated reset password request is currently being reworked to provide a ' + 
        'smoother experience.  In the mean time, please email Admin@QuantumKitty.org to have your password reset. ' + 
        'Please send the email from the email address you used to sign up and include your name, display name, ' + 
        'email address, and phone number.  We will only call if there is a problem reseting your password.  ' + 
        'Thanks for your patience as we continue to upgrade the system.' 
        showModal.value = true
    }
        
    const completeProcess = () => {
        showModal.value = false
        // router.push({ name: 'Home'})
    }

    return { email, password, handleSubmit, handleResetPassword, error, errorMsg, 
              showModalMessage, headerText, messageText, showModal, completeProcess }
  }
}
</script>