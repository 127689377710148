<template>

<div class="signup">
    <form @submit.prevent="handleSubmit"> 
      <h2>Sign up</h2>
      <br>

      <label for="displayName">Display Name:</label>
      <input type="text" name="displayName" v-model="displayName" required>

      <label for="email">Email:</label>
      <input type="email" name="email" v-model="email" required>

      <label for="password">Password:</label>
      <input type="password" name="password" v-model="password" required>

      
      <button v-if="!isPending">Sign Up</button>
      <button v-if="isPending" disabled>Loading</button>

      <p>{{ displayName.value }}</p>

      <div v-if="error">{{ error }}</div>
    </form>
    <br>
    <!-- <button @click="handleGoogleSignin">Sign in with Google</button> -->  
  </div>
  <div>
    <p>
      <small>
        Trouble signing up? &nbsp; Send an email to Admin@QuantumKitty.org
      </small>
    </p>
    <br>
  <!-- <img height='0' width='0' alt='' src='https://pixel.watch/ksn7' />  -->
  </div>
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import useSignup from '../composables/useSignup'
// import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth'
// import { auth  } from '../firebase/config'
// import { from } from 'core-js/library/js/array'

export default {
  setup() {
    const { signup, error, isPending } = useSignup()

    const email = ref('')
    const password = ref('')
    const displayName = ref('')

    const router = useRouter()

    const handleSubmit = async () => {
      console.log('New user about to sign up -- ', email.value, password.value)
      console.log('New user about to sign up -- ', displayName.value)

      const res = await signup(email.value, password.value, displayName.value)
console.log('error.value = ', error.value)
      if (!error.value) {
        router.push({ name: 'Home'})
        // router.push('/About')
      }
    }

    // const handleGoogleSignin = () => {
    //   const provider = new GoogleAuthProvider()
    //   return signInWithPopup(firebaseAuth, provider)
    //     .then(() => router.replace({ name: 'Account'}))
    // }

    // return { email, password, handleSubmit, handleGoogleSignin, error, isPending, displayName }
    return { email, password, handleSubmit, error, isPending, displayName }
  }
}
</script>
