<template>
<div v-if="game">
  <h2 >Selecting numbers for {{ game.value.name }}  draw date: {{ game.value.nextDrawDate }}</h2>
  <br>
  <form action="" @submit.prevent="handleSubmit">
    <h3>Select the winning numbers</h3>
    <p>Please select {{ game.value.mainPicks }} from first group</p>
    <p v-if="game.value.extraNumbers > 0">and {{ game.value.extraPicks }} from the second group</p> 
    <p>then click Submit</p>

    <br><br>
    <div class="pickEm" >
        <div v-for="i in game.value.mainNumbers" class="pickbox" :key="i">
            <div class="mainPicks">
                <input type="checkbox" :id="i" :value="i"  v-model.number="mPicks" @mouseup="showPicks(i)">
                <label>{{ i }}</label>
            </div>
        </div>
    </div>
    <br>
    <p v-if="mPicks.length > game.value.mainPicks" class="error">Please select only {{ game.value.mainPicks }} numbers</p>
    <p>Your Picks = {{ mPicks }}</p>
    <br><br>

    <div class="pickEm" >
        <div v-for="i in game.value.extraNumbers" class="pickbox" :key="i">
            <div class="mainPicks">
                <input type="checkbox" :id="i" :value="i"  v-model.number="ePicks" @mouseup="showPicks(i)">
                <label>{{ i }}</label>
            </div>
        </div>
    </div>
    <br>
    <p v-if="ePicks.length > game.value.extraPicks" class="error">Please select only {{ game.value.extraPicks }} number</p>
    <p>Your Picks = {{ ePicks }}</p>
    <br>
    <button :disabled="!(mPicks.length == game.value.mainPicks && ePicks.length == game.value.extraPicks)" >Submit</button>
    
  </form>

</div>  
</template>

<script>
import { ref, toRaw, reactive, toRefs } from '@vue/reactivity'
import getDocument from '../composables/getDocument'
import { onUpdated, onMounted } from '@vue/runtime-core'
import { useRouter } from 'vue-router'
// import sayHi from '../composables/sayHi'
import updateCounts from '../composables/updateCounts'
import { Timestamp } from '@firebase/firestore'
import updatePicks from '../composables/updatePicks'

export default {
    props: ['id'],

    setup(props) {
        const router = useRouter()
        const game = ref(null)
        const thisGame = ref()
        const myResult = ref()
        const gameId = props.id

/////////////////////////////////////////////////////////////////////////////////////////        

        const getMyDoc = async () => { 
            const thisGame = await (await getDocument('games', props.id)).myResult
            game.value = toRaw(thisGame)

        return { game }
        }
        getMyDoc()


//////// Both ways work now  //////////////////////////////////////////////////////////

        // const getMyDoc = async () => { 
        //     const shit = await (await getDocument('games', props.id)).myResult
        //     thisGame.value = toRaw(shit)
        //     if (thisGame.value) {
        //         console.log('I have a thisGame value! ', thisGame)
        //     }
        // return { thisGame }
        // }

        // const returnValue  = async () => await getMyDoc()
        //     .then((onfulfilled) => { 
        //         game.value = thisGame
        //     })
        // returnValue()

////////////////////////////////////////////////////////////////////////////////
        
        const mainNumbers = 0
        const extraNumbers = 0
        const mPicks = ref([])
        const ePicks = ref([])

        onUpdated(() => {
            // console.log('In onUpdated') 
            // console.log('In onUpdated - game = ', game)
        })

        const showPicks = (id) => {
            // console.log('id = ', { id })
            // console.log('mPicks = ', mPicks)
            // console.log('document.getElementById( id ) = ', document.getElementById( id ) )
            // console.log('document.getElementById( id ).value = ', document.getElementById( id ).value )
        }
        const handleSubmit = async () => {
            // console.log('Need to store their picks in new DB')
            // console.log('mPicks.value = ', mPicks.value)
            // console.log('ePicks.value = ', ePicks.value)
            const myColl = 'counts'
            const drawDate = game.value.value.nextDrawDate
            // console.log('drawDate = ', drawDate)
            const ImDone = updateCounts( myColl, gameId, drawDate, mPicks, ePicks )
            // console.log('ImDone = ', ImDone.value)
            const ImDoneToo = updatePicks( 'picks', gameId, drawDate, mPicks, ePicks )
            // console.log('ImDoneToo = ', ImDoneToo.value)

            router.push({ name: 'UserHome' })
        }

    return { game, mPicks, ePicks, mainNumbers, extraNumbers, showPicks, handleSubmit } 
    }
}
</script>
