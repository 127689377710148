import { ref } from "vue"

const sayHi = async (name, msTime) => {
    // console.log('Hi ', name)
    const myRand = Math.floor(Math.random()*100) + 1
    const runTime = ref(300)
    if (msTime) {
        runTime.value = msTime
    }
    // console.log('runTime.value = ', runTime.value)

    let done = await setTimeout(() => {
        // console.log(name,'your random number between 1 & 100 is:', myRand)       
    }, runTime.value)
    
    console.log('Number done = ', done)
    let out = true
    return { out }
}
export default sayHi 