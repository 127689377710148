import { ref } from 'vue'
import { firebaseAuth } from '../firebase/config'
// import { auth } from '../firebase/config'
import { signOut } from 'firebase/auth'

// refs
const error = ref(null)
const isPending = ref(false)
const user = ref(firebaseAuth.currentUser)
// const user = ref(auth.currentUser)

// logout function
const logout = async () => {
  error.value = null
  isPending.value = true

  signOut(firebaseAuth)
  // signOut(auth)
  .then(() => {
    if(1 == 1)
    console.log('Sign-out successful.')
  }).catch((error) => {
    consloe.log('A signout error happened.')
  });
}  

const useLogout = () => {
  return { error, logout, isPending, signOut }
}

export default useLogout