import { getApps, initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { getFunctions } from '@firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyABov5-22aMV6qRC_1YLPSYznirxPD7N74",
  authDomain: "quantum-kitty.firebaseapp.com",
  projectId: "quantum-kitty",
  storageBucket: "quantum-kitty.appspot.com",
  messagingSenderId: "713703408510",
  appId: "1:713703408510:web:84018e231b84b67b7a292f"
};

// const firebaseConfig = {
//   apiKey: process.env.VUE_APP_API_KEY,
//   authDomain: process.env.VUE_APP_AUTH_DOMAIN,
//   projectId: process.env.VUE_APP_PROJECT_ID,
//   storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
//   messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
//   appId: process.env.VUE_APP_APP_ID
// };

// // Stuff from .env  
// VUE_APP_API_KEY="AIzaSyABov5-22aMV6qRC_1YLPSYznirxPD7N74",
// VUE_APP_AUTH_DOMAIN="quantum-kitty.firebaseapp.com",
// VUE_APP_PROJECT_ID="quantum-kitty",
// VUE_APP_STORAGER_BUCKET="quantum-kitty.appspot.com",
// VUE_APP_MESSAGING_SENDER_ID="713703408510",
// VUE_APP_APP_ID="1:713703408510:web:84018e231b84b67b7a292f"

const apps = getApps()
const firebaseApp = !apps.length ? initializeApp(firebaseConfig) : apps[0]

//const firebaseApp = initializeApp(firebaseConfig)
// initializeApp(firebaseConfig)


// init services 
const qkdb = getFirestore()
const firebaseAuth = getAuth(firebaseApp)
// firebaseAuth.tenantId = 'Kitty'

const getCurrentUser = () => new Promise((resolve, reject) => {
  const unsub = onAuthStateChanged(firebaseAuth, user => {
    unsub()
    resolve(user)
  }, reject)
})

export { qkdb, firebaseAuth, firebaseApp, getCurrentUser }

// Pre Stripe Subscription version /////////////////////////////////////
// import { initializeApp } from 'firebase/app'
// import { getFirestore } from 'firebase/firestore'
// import { getAuth } from 'firebase/auth'


// const firebaseConfig = {
//   apiKey: "AIzaSyABov5-22aMV6qRC_1YLPSYznirxPD7N74",
//   authDomain: "quantum-kitty.firebaseapp.com",
//   projectId: "quantum-kitty",
//   storageBucket: "quantum-kitty.appspot.com",
//   messagingSenderId: "713703408510",
//   appId: "1:713703408510:web:84018e231b84b67b7a292f"
// };

// //   init firebase
// initializeApp(firebaseConfig)

// // init services 
// const qkdb = getFirestore()
// const auth = getAuth()

// export { qkdb, auth }