<template>
    <div class="qkdetails">
        <div class="info">
            <h3 id="QKDtop">
                How we are going to make use of this
            </h3>
            <p>
                We are gathering together a group of people who will attempt to sense the upcoming winning numbers 
                for Powerball, Mega Millions, and Lucky For Life.  We will then analyze this input and try to 
                determine the most likely numbers to be drawn in a given game.  This information will of course 
                be shared with all those that have chosen to participate in this scientific study.  We will be 
                keeping this group as small as possible to maintain integrity of the targeted gaming systems.  
                As soon as we start seeing positive results we will stop accepting new members and attempt to 
                fine tune the data to achieve better outcomes.  Many people (I’m sure that includes some in 
                this group) have tried to use manifestation to help them win the lottery by willing the numbers 
                drawn to match their ticket.  While this may be possible, you would be fighting against all the 
                other people out there trying to make the numbers drawn match their tickets.  We don’t care what 
                the numbers are as much as being able to know them ahead of time.  But then again, with all of us 
                pulling for the numbers we determined to be likely winners, we may have a leg up on other 
                manifestors based on the size of our group.  In this we create a self-fulfilling prophesy, with 
                all of us being on the winning side.
            </p>
            <br>
            <p>
                Our first goal is to be able to consistently predict three numbers in a given game.  At this 
                point, anyone who makes use of correctly selected numbers would be able to profit from ticket 
                purchases.  Even before reaching this goal, we will be able to share one, and then two numbers 
                thought to be amongst those selected in future drawings.  We would thereby be giving our members 
                a head start in picking winning numbers.  And when we get to the point of regularly predicting 
                three numbers, our members would have a resonable chance of selecting one more correct number
                making for a four ball win.  Of course, we aren't going to stop there, we will continue to hone 
                our number prediction abilities, ever increasing the quantity of correct numbers we are able to 
                consistently predict.
            </p>
            <br>
            <p>
                The tables below illustrate the earnings potential of the different games when correctly choosing 
                three balls as well as four ball wins.  
            </p>
            <br>
            <p>
                Example 1: If you play Powerball without the Power Play option, the ticket would cost $2 and you would 
                collect winnings of $7 with $5 in profit ($7 in winnings minus $2 for the ticket).  If you were able to 
                correctly select one more number on your own, this would jump up to a profit of $98.
            </p>
            <div style="height:10px;font-size:1px;">&nbsp;</div>  <!--  adjustable height line break -->
            <p>
                Example 2: If you play Powerball with the Power Play option, the ticket would cost $3, if the 
                Power Play multiplier drawn is 3, you would collect winnings of $21 with a profit of $18 ($7 
                in initial winnings multiplied by the Power Play number of 3 for total winnings of $21 minus 
                the $3 cost of the ticket for a profit of $18).  If you were able to correctly select one more 
                number on your own, this would jump up to a profit of $297.
            </p>            
            <div style="height:10px;font-size:1px;">&nbsp;</div>  <!--  adjustable height line break -->
            <p>
                Example 3: If you play Powerball with the Power Play option, the ticket would cost $3, if 
                Powerball jackpot is below $150,000,000 and the Power Play multiplier drawn is 10, you would 
                collect winnings of $70 with a profit of $67 ($7 in initial winnings multiplied by the Power 
                Play number of 10 for total winnings of $70 minus the $3 cost of the ticket for a profit of $67).  
                If you were able to correctly select one more number on your own, this  
                would jump up to a profit of $997.
            </p>
            <br>
            <div class="pic">
                <img width="90%" src="@/assets/powerball-chart.png" alt="Powerball Chart">
            </div>
            <br>
            <br>
            <p>
                Example 4: If you play Mega Millions without the Megaplier option, the ticket would cost $2 and 
                you would collect winnings of $10 with $8 in profit ($10 in winnings minus $2 for the ticket).
                If you were able to correctly select one more number on your own, this would jump up to a profit of $498.
            </p>
            <div style="height:10px;font-size:1px;">&nbsp;</div>  <!--  adjustable height line break -->
            <p>
                Example 5: If you play Mega Millions with the Megaplier option, the ticket would cost $3, 
                if the Megaplier multiplier drawn is 4, you would collect winnings of $40 with a profit of 
                $37 ($10 in initial winnings multiplied by the Megaplier number of 4 for total winnings of 
                $40 minus the $3 cost of the ticket for a profit of $37).  If you were able to correctly select 
                one more number on your own, this would jump up to a profit of $1997.
            </p>            
            <br>
            <div class="pic">
                <img width="90%" src="@/assets/mega-millions-chart.png" alt="Mega Millions Chart">
            </div>
            <br>
            <br>
            <p>
                Example 6: If you play Luck For Life, the ticket would cost $2 and got three regular 
                balls correct, you would collect winnings of $20 with $18 in profit ($20 in winnings 
                minus $2 for the ticket).  If you were able to correctly select one more number on your 
                own, this would jump up to a profit of $148.
            </p>
            <br>
            <div class="pic">
                <img src="@/assets/lucky-for-life-chart.png" alt="Lucky For Life Chart">
                <!-- <img width="100%" src="@/assets/lucky-for-life-chart.png" alt="Lucky For Life Chart"> -->
            </div>
            <br><br>
            <h3>
                Member training
            </h3>
            <p>
                Of course we won't stop when we get to consistently predicting just three balls, we will continue 
                to fine tune our selection algorithms to increase the number of balls we can consistently predict 
                correctly.   In conjunction with this, we will also be continually developing training tools so user can 
                improve their abilities as well. We are offering this training free to all Quantum Kitty members in the 
                training section of this website. 
            </p>
            <br>
            <h3>
                What we expect from participants
            </h3>
            <p>
                You are encouraged to regularly go to the website and carefully select the numbers you believe 
                will be those drawn in the next drawings of the games.  This can be achieved in as little as a 
                few minutes on the site per week.  However, you will probably also want to visit daily so you can 
                obtain the latest numbers forecast to win.  We would also appreciate it if you selected your numbers 
                as early as possible so we have time to crunch the numbers and get the data out to participants.
            </p>
            <br>
            <p>
                We believe that through this program all of us will be able drastically increase our disposable 
                income and we intend to do as much good in the world as we can with this new found wealth.  We 
                would like to suggest that all those who choose to participate in this project follow our lead, 
                and help others in need, and donate to worthy causes.  For us, it brings great joy to do good 
                things, you will probably find it does for you as well.  Additionally, it never hurts to have 
                karma on your side when what you are doing could be considered playing with luck.
            </p>
            <br>
            <h3>
                Why we charge a fee
            </h3>
            <p>
                We wanted to do this as a free service available to all those that chose to participate.  We do 
                not believe in charging for something that doesn’t work, and if it does work, we will all be 
                making more money than we know what to do with.  That being said, in the world today there are 
                those who would sign up and enter faulty picks just to mess up the study.  We are charging a 
                fee that we believe to be small enough that everyone can afford it and just large enough to 
                eliminate the majority of those bad apples out there that just want to see the project fail.  
                It has been shown that when people have paid a fee, even as small as the one we are charging, 
                they will participate on a more consistent basis, something we need to make this work with as 
                small a group as possible.  This fee will also help offset the expenses we have in initially 
                setting up the study and analyzing the data, as well as ensuring reliable numbers for our participants.
            </p>
            <br>
            <h3>
                Membership
            </h3>
            <p>
                We offer memberships on a monthly and yearly basis.  Memberships will allow access to forecast 
                numbers for all games currently under study; Powerball, Mega Millions, and Lucky For Life.  
                Monthly memberships will be $7.77 and yearly will be discounted to $77.77.  Yes, we did 
                intentionally choose “lucky” number 7, might as well take every advantage we can.  
            </p>
            <br>
            <p>
                Remember, we will be limiting the number of memberships so sign up today to make sure you 
                will have access to the daily winning numbers.  
            </p>
            <br>
            <p>
                Memberships do not come with any guarantee of winning and if you do choose to purchase tickets 
                based on our forecast numbers you should not spend any money you can not afford to lose.
            </p>
            <br>
        </div>
    <br> 
    <div v-if="!user">
      <p class="bot-menu">
        Please signup now to learn more about the Quantum Kitty project and how you can benefit.  
        Become a Quantum Kitty member before all the memberships are taken and you can take full 
        advantage of the research results and make money by doing so. 
      </p>
      <br><br>
      <p>
        Still have more questions?
        <br>
        <router-link class="btn" to="/about">Contact Us</router-link>
      </p>
    </div> 
    <br><br>
  <div>
    <div v-if="user" class="bot-menu">
      <div>
        <router-link class="btn" to="/">Go Home</router-link>
      </div>
      &nbsp; &nbsp;
      <div class="pic">
          <img width="100" class="kitty" src="@/assets/cat-sleeping.png" alt="Sleepy Kitty">
      </div>
      &nbsp; &nbsp;
      <div>
        <router-link class="btn" to="/UserHome">Go to Games</router-link>
        <!-- <a href="/QKDetails" class="btn" >Continue Reading</a> -->
      </div>     
    </div>
    <div v-else class="bot-menu">
      <router-link class="btn sale" :to="{ name: 'Login' }">Log In</router-link>   
      &nbsp; &nbsp;
      <div class="pic">
          <img width="100" class="kitty" src="@/assets/cat-sleeping.png" alt="Sleepy Kitty">
      </div>
      &nbsp; &nbsp;
        <!-- <a href="/QKDetails" class="btn" >Continue Reading</a> -->
      <router-link class="btn sale" :to="{ name: 'Signup' }">Sign Up</router-link>
    </div>
  </div>
  <br><br>
  <!-- ClickMeter.com page views tracking: QK Details  -->
  <!-- <script type='text/javascript'> var ClickMeter_pixel_url = '//pixel.watch/4fgq'; </script>  -->
  <!-- <script type='text/javascript' id='cmpixelscript' src='//s3.amazonaws.com/scripts-clickmeter-com/js/pixelNew.js'></script>  -->
  <!-- <noscript>  -->
    <!-- <img height='0' width='0' alt='' src='https://pixel.watch/4fgq' />  -->
    <!-- <img height='0' width='0' alt='' src='https://pixel.watch/4fgq?User=${user.displayName}' />  -->
    <!-- </noscript> -->

        <br><br>
    </div>
</template>

<script>
import getUser from '../composables/getUser'

export default {
  name: 'QKDetails',
  setup() {
    const { user } = getUser()
    // console.log('user = ', user)
    // console.log(process.env);

    return { user }
  }

}
</script>
