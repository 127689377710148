<template>
    <div>
        <h2>404</h2>
        <br>
        <h3>Page Not Found</h3>
        <br>
        <h4>Oh My God!</h4>
        <br>
        <h5>What have you done?!?!?</h5>
        <br>
        <p>What did you click?  &nbsp; &nbsp;  You broke the kitty!  </p>
        <br>
         <p>It must have been something you did, it couldn't have possibly been something we did! &nbsp; &nbsp;  ;^)</p>
        <br>
    </div>
        <br>

    <div class="bot-menu">
        <div>
            <router-link class="btn" to="/UserHome">Go to Games</router-link>
        </div>
        <div class="pic">
            <img width="100" class="kitty" src="@/assets/cat-sleeping.png" alt="Sleepy Kitty">
        </div>
        <div>
            <router-link class="btn" to="/">Go Home</router-link>
        </div>  
    </div>
    <br><br>
</template>

<script>
export default {

}
</script>

<style>

</style>