<template>
    <div class="backdrop" @click.self="$emit('close')">
        <div class="modal" >
            <slot></slot>
            <h2>Quantum Kitty Says:</h2>
            <br>
            <h3>{{ headerText }}</h3>
            <p>{{ messageText }}</p>
  
            <br>
            <br>
            <button @click="$emit('close')">Ok</button>
        </div>
    </div>
</template>

<script>
export default {
    props: [ 'headerText', 'messageText' ],
    
}
</script>
